import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import toast, { Toaster } from "react-hot-toast";

import {
  postAnswer,
  createPlaylist,
  getSessionQuestions,
  getPlaylistQuestions,
  resetRestart,
} from "features/medecine";

import * as questionUtils from "../utils/questionUtils";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Statistics from "components/quiz/Statistics";
import NoteModal from "components/dashboard/NoteContent";
import SignalModal from "components/quiz/SignalModal";
import PropositionCheckbox from "./PropositionCheckbox";
import renderSkeleton from "./renderSkelton";
import Comment from "./Comment";

const Question = () => {
  const dispatch = useDispatch();
  const {
    loadingQuestions,
    questions,
    session_id,
    playlist,
    questions_length,
    selectedCourseId,
    restart,
  } = useSelector((state) => state.medecine);

  const course_id = selectedCourseId;

  const questionsLenRef = useRef(questions_length);

  useEffect(() => {
    if (restart) {
      // Update ref only if this is a new restart
      questionsLenRef.current = questions_length;
    }
    dispatch(resetRestart());
  }, [questions_length, restart]);

  const [isCommentOpen, setIsCommentOpen] = useState(false);

  const toggleCommentDrawer = () => {
    setIsCommentOpen((prevState) => !prevState);
  };

  useEffect(() => {
    if (questionsLenRef.current === undefined) {
      questionsLenRef.current = questions_length;
    }
  }, [questions_length]);
  const questions_len = questionsLenRef.current;
  const [newSessionStats, setNewSessionStats] = useState({
    fauxCount: 0,
    justeCount: 0,
    partJusteCount: 0,
    partielNegatif: 0,
    partielPositif: 0,
    toutOuRien: 0,
    selectedPartCours: [],
    marksByPartCours: {},
    dureeTotale: {},
    dureeMoyenne: {},
  });
  const [verified, setVerified] = useState(false);
  const [questionIndexClasses, setQuestionIndexClasses] = useState({});
  const [finished, setFinished] = useState(false);
  const [evaluation, setEvaluation] = useState(null);
  const [answeredQuestionsLength, setAnsweredQuestionsLength] = useState(0);

  const data = [
    newSessionStats.fauxCount,
    newSessionStats.justeCount,
    newSessionStats.partJusteCount,
  ];
  const labels = ["Faux", "justes", "Part/ justes"];

  const options = {
    series: data,
    labels: labels,
    colors: ["#F44336", "#4CAF50", "#FF9800"],
    chart: {
      type: "donut",
    },
    toolbar: {
      show: false,
    },
  };

  let old_data = [];
  let old_options = {};

  const [page, setPage] = useState(1);
  const [visitedQuestions, setVisitedQuestions] = useState([]);
  const questionTextRef = useRef(null);
  const [currentMyQuestionIndex, setCurrentMyQuestionIndex] = useState(0);

  let currentQuestion = questions[currentMyQuestionIndex];
  const inputRefs = useRef([]);

  const showSwalTerminerLink = () => {
    const MySwal = withReactContent(Swal);

    MySwal.fire({
      text: "Voulez-vous vraiment terminer cette session ?",
      icon: "warning",
      buttonsStyling: true,
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      cancelButtonColor: "#6c757d",
      confirmButtonText: "Terminer",
      cancelButtonText: "Annuler",
      customClass: {
        confirmButton: "btn btn-danger mx-2",
        cancelButton: "btn btn-success",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        Terminer();
      }
    });
  };

  const handleVerify = () => {
    const updatedVisitedQuestions = [...visitedQuestions];
    updatedVisitedQuestions[currentMyQuestionIndex] = true;
    setVisitedQuestions(updatedVisitedQuestions);

    const selectedPropositionIds = [];
    const questionPropsIds = [];
    const updatedPropositions = questions[
      currentMyQuestionIndex
    ].propositions.map((proposition) => {
      const checkbox = document.getElementById(
        `kt_radio_option_${proposition.id}`
      );
      const isChecked = checkbox.checked;
      if (isChecked) {
        selectedPropositionIds.push(proposition.id);
      }
      if (proposition.is_correct) {
        questionPropsIds.push(proposition.id);
      }
      return { ...proposition, isChecked };
    });

    // Assuming questionUtils.updatePropositionLabels and other functions
    questionUtils.updatePropositionLabels(updatedPropositions);
    const evaluation = questionUtils.updateAnswerCounts(
      questionPropsIds,
      questions[currentMyQuestionIndex],
      selectedPropositionIds,
      setNewSessionStats,
      setEvaluation,
      questionIndexClasses,
      setQuestionIndexClasses
    );
    setVerified(true);
    const proposition_ids = selectedPropositionIds;
    const question_id = questions[currentMyQuestionIndex]?.id;

    dispatch(
      postAnswer({ question_id, proposition_ids, evaluation, session_id })
    );
  };

  const prevQuestion = () => {
    if (currentMyQuestionIndex > 0) {
      setCurrentMyQuestionIndex(currentMyQuestionIndex - 1);
      setVerified(true);
    }
  };

  const nextQuestion = () => {
    if (currentMyQuestionIndex < questions_len - 1) {
      const nextQuestionIndex = currentMyQuestionIndex + 1;

      // Fetch new questions if the next index is a multiple of 5 and hasn't been visited
      if (nextQuestionIndex % 5 === 0 && !visitedQuestions[nextQuestionIndex]) {
        if (session_id) {
          dispatch(getSessionQuestions({ session_id: session_id }));
        } else {
          const page = Math.floor(nextQuestionIndex / 5) + 1;
          dispatch(getPlaylistQuestions({ course_id, page }));
        }
      }

      // Update verification status based on whether the next question has been visited
      setVerified(visitedQuestions[nextQuestionIndex] || false);
      console.log(visitedQuestions);
      console.log(nextQuestionIndex);

      // Move to the next question
      setCurrentMyQuestionIndex(nextQuestionIndex);
    }
  };

  const Terminer = () => {
    setFinished(true);
    setVerified(false);

    const answered_questions_length =
      newSessionStats.fauxCount +
      newSessionStats.justeCount +
      newSessionStats.partJusteCount;
    setAnsweredQuestionsLength(answered_questions_length);
  };

  const buttonReff = useRef(null);
  const [playlistMessage, setPlaylistMessage] = useState(null);
  const notify = () => toast.success(playlistMessage);

  const handleButtonClick = () => {
    const toastButton = buttonReff.current;
    if (toastButton) {
      if (playlist) {
        setPlaylistMessage("Question ajoutée aux playlists avec succès");
      } else {
        setPlaylistMessage("Question supprimée des playlists avec succès");
      }
      toastButton.click();
    }
  };
  const handleCreatePlaylist = () => {
    const question_id = currentQuestion.id;
    dispatch(createPlaylist({ question_id }));

    handleButtonClick();
  };

  const showSwalWithLink = () => {
    const MySwal = withReactContent(Swal);

    MySwal.fire({
      text: "Voulez-vous vraiment supprimer cette question de la playlist ?",
      icon: "warning",
      buttonsStyling: true,
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      cancelButtonColor: "#6c757d",
      confirmButtonText: "Supprimer",
      cancelButtonText: "Annuler",
      customClass: {
        confirmButton: "btn btn-danger mx-2",
        cancelButton: "btn btn-success",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const question_id = currentQuestion.id;
        dispatch(createPlaylist({ question_id })).then(() => {
          handleButtonClick();
        });
      }
    });
  };

  const showQuestionInfo = () => {
    const MySwal = withReactContent(Swal);

    MySwal.fire({
      title: "Infos sur la question",
      html: `
      <div style="text-align: left;">
        <p>Module: ${currentQuestion.module}</p>
        <p>Cours: ${currentQuestion.course}</p>
        <p>Sources:</p>
        <ul>
          ${currentQuestion.sources
            ?.map((source) => `<li>${source}</li>`)
            .join("")}
        </ul>
        <p>Année:</p>
        <ul>
          ${currentQuestion.annee_univ
            ?.map((year) => `<li>${year}</li>`)
            .join("")}
        </ul>
      </div>
    `,
      icon: "info",
      buttonsStyling: true,
      showCancelButton: true,
      showConfirmButton: false,
      cancelButtonColor: "#6c757d",
      cancelButtonText: "Ok",
      customClass: {
        cancelButton: "btn btn-success",
      },
    });
  };

  const showExplanation = () => {
    const MySwal = withReactContent(Swal);

    const explanation = currentQuestion.explanation;
    if (typeof explanation !== "string") {
      console.error("Explanation is not a string");
      return;
    }
    console.log(explanation);

    // Replace both \n and \r\n with <br>
    const formattedExplanation = explanation.replace("\\n", "<br>");

    // Log the formatted explanation to ensure \n was replaced
    console.log(formattedExplanation);

    MySwal.fire({
      html: formattedExplanation, // Ensure you're using 'html' for HTML content
      buttonsStyling: true,
      showCancelButton: true,
      showConfirmButton: false,
      width: 1000,
      heightAuto: false,
      padding: "1em",
      customClass: {
        popup: "auto-size-popup",
      },
    });
  };

  // Function to render the "Terminer" button
  const renderTerminerButton = () => (
    <button
      className="btn btn-secondary btn-sm fw-semibold text-white me-3"
      onClick={showSwalTerminerLink}
    >
      Terminer
    </button>
  );

  // Function to render the "Précédent" button
  const renderPrevButton = (isEnabled) => {
    const isMobile = window.innerWidth <= 768;

    return (
      <button
        type="button"
        onClick={isEnabled ? prevQuestion : null}
        className={`btn btn-precedent ${
          isMobile ? "btn-icon" : ""
        } btn-sm hover-rotate-start text-white me-1 ${
          !isEnabled ? "disabled" : ""
        }`}
        disabled={!isEnabled}
      >
        <i className="ki-duotone ki-double-left-arrow text-white fs-2">
          <span className="path1"></span>
          <span className="path2"></span>
        </i>
        {!isMobile && <span> Précédent</span>}
      </button>
    );
  };

  const renderNextButton = (isEnabled) => {
    const isMobile = window.innerWidth <= 768;

    return (
      <button
        type="button"
        onClick={isEnabled ? nextQuestion : null}
        className={`btn btn-danger ${
          isMobile ? "btn-icon" : ""
        } btn-sm hover-rotate-end me-1 ${!isEnabled ? "disabled" : ""}`}
        disabled={!isEnabled}
      >
        {!isMobile && <span> Suivant</span>}
        <i className="ki-duotone ki-double-right-arrow text-white fs-2">
          <span className="path1"></span>
          <span className="path2"></span>
        </i>
      </button>
    );
  };

  // Function to render the "Verifier" button
  const renderVerifierButton = (isEnabled) => (
    <button
      className={`btn btn-info btn-sm me-1         
      `}
      onClick={isEnabled ? handleVerify : null}
    >
      Verifier
    </button>
  );

  const renderExplainButton = () => (
    <button className={`btn btn-dark btn-sm me-1 `} onClick={showExplanation}>
      Explication
    </button>
  );

  const renderButtons = () => {
    return (
      <div className="d-flex flex-row-fluid flex-end">
        {renderPrevButton(currentMyQuestionIndex >= 1)}
        {renderNextButton(
          currentMyQuestionIndex < questions_len - 1 &&
            visitedQuestions[currentMyQuestionIndex]
        )}
        {verified
          ? renderExplainButton()
          : renderVerifierButton(!visitedQuestions[currentMyQuestionIndex])}
      </div>
    );
  };

  const renderAnneeUniv = (question) => {
    return question?.annee_univ?.length > 0
      ? question.annee_univ
          .filter((annee) => annee !== "nan")
          .map((annee, index) => (
            <span key={index} className="badge badge-info mx-0">
              {annee}
            </span>
          ))
      : null;
  };

  const renderQuestion = (question, index) => (
    <div
      key={index}
      className="card card-flush border-gray-700 card-stretch-75"
      hidden={index !== currentMyQuestionIndex}
    >
      <div className="card-header border-gray-700 p-3 pb-0 d-block ribbon ribbon-top">
        <div className="ribbon-label bg-secondary">
          {`Question ${currentMyQuestionIndex + 1}/${questions_len}`}
        </div>
        <div className="card-title fs-4 p-3">
          <div className="d-flex flex-column">
            <div className="d-flex flex-row">
              <div
                ref={questionTextRef}
                dangerouslySetInnerHTML={{ __html: question?.question_text }}
              />
            </div>
            <div className="d-flex flex-row gap-2 mt-2">
              {question?.sources?.length > 0 && (
                <span className="badge badge-success">
                  {question.sources.join(", ")}
                </span>
              )}
              {renderAnneeUniv(question)}
            </div>
          </div>
        </div>
      </div>
      <div className="card-body bg-transparent">
        {question?.propositions?.map((proposition, index) => (
          <PropositionCheckbox
            key={proposition.id}
            proposition={proposition}
            index={index}
            inputRefs={inputRefs}
            currentMyQuestionIndex={currentMyQuestionIndex}
            visitedQuestion={visitedQuestions[currentMyQuestionIndex]}
          />
        ))}
      </div>
      <div className="card-footer d-flex flex-end">
        {renderTerminerButton()}
        {renderButtons()}
      </div>
    </div>
  );
  return (
    <>
      <button
        hidden={true}
        type="button"
        className="btn btn-primary"
        onClick={notify}
        ref={buttonReff}
      >
        Toggle Toast
      </button>
      <div>
        <Toaster position="top-right" reverseOrder={false} />
      </div>

      <SignalModal currentQuestion={currentQuestion} />

      <div className={finished ? "col-xl-12 mb-5" : "col-xl-8 mb-5"}>
        {!finished &&
          (loadingQuestions
            ? renderSkeleton()
            : questions?.map((question, index) =>
                renderQuestion(question, index)
              ))}

        {finished && (
          <Statistics
            old_options={old_options}
            old_data={old_data}
            options={options}
            data={data}
            newSessionStats={newSessionStats}
            setNewSessionStats={setNewSessionStats}
            setFinished={setFinished}
            setCurrentMyQuestionIndex={setCurrentMyQuestionIndex}
            setVerified={setVerified}
            setVisitedQuestions={setVisitedQuestions}
            session_id={session_id}
          />
        )}

        {!finished && (
          <div className="app-engage">
            <button
              className="app-engage-btn hover-success"
              onClick={showQuestionInfo}
            >
              <i className="ki-solid ki-information fs-1 pt-1 mb-2"></i>
              {/* <span className="fs-9">Info</span> */}
            </button>
            {currentQuestion?.is_playlist ? (
              <button
                className="app-engage-btn hover-success"
                onClick={showSwalWithLink}
              >
                <i className="ki-solid ki-archive-tick fs-1 pt-1 mb-2"></i>
                {/* <span className="fs-9">Playlists</span> */}
              </button>
            ) : (
              <button
                className="app-engage-btn hover-success"
                onClick={handleCreatePlaylist}
              >
                <i className="ki-outline ki-archive-tick fs-1 pt-1 mb-2"></i>
                {/* <span className="fs-9">Playlists</span> */}
              </button>
            )}
            <button
              className="app-engage-btn hover-success"
              data-bs-toggle="modal"
              data-bs-target="#kt_modal_signal"
            >
              <i className="ki-outline ki-flag fs-1 pt-1 mb-2"></i>{" "}
              {/* <span className="fs-9">Signaler</span> */}
            </button>

            <button
              className={`app-engage-btn btn-light hover-success ${
                !verified ? "opacity-50" : ""
              }`}
              disabled={!verified}
              onClick={showExplanation}
            >
              <i className="ki-solid ki-notepad-bookmark fs-1 pt-1 mb-2"></i>
              {/* <span className="fs-9">Explication</span> */}
            </button>
            <button
              className={`app-engage-btn btn-info hover-success ${
                !verified ? "opacity-50" : ""
              }`}
              disabled={!verified}
              onClick={toggleCommentDrawer}
            >
              <div>
                <i className="ki-duotone ki-message-notif fs-1 pt-1 mb-2">
                  <span className="path1"></span>
                  <span className="path2"></span>
                  <span className="path3"></span>
                  <span className="path4"></span>
                  <span className="path5"></span>
                </i>
              </div>
              {/* <span className="fs-9">Comments</span> */}
            </button>
          </div>
        )}
      </div>
      {!finished && (
        <div className="col-xl-4 mb-5">
          <NoteModal course_id={course_id} />
          <Comment
            currentQuestion={currentQuestion}
            isCommentOpen={isCommentOpen}
            toggleCommentDrawer={toggleCommentDrawer}
          />
          {/* <div className="card card-flush pt-1 mb-5 mb-lg-10">
            <div className="card-header">
              <div className="card-title">
                <h2 className="fw-bold">Raccourci</h2>
              </div>
            </div>
            <div className="card-body pt-0">
              {questions.map((question, index) => (
                <button
                  key={index}
                  id={`question-index-${question.id}`}
                  className={`btn ${getButtonClassName(index)} ${
                    questionIndexClasses[question.id]
                  } me-1 mb-2`}
                  onClick={() => switchBetweenQuestions(index)}
                >
                  {String(index + 1).padStart(2, "0")}
                </button>
              ))}
            </div>
          </div> */}
        </div>
      )}
    </>
  );
};

export default Question;
