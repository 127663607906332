import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import DOMPurify from "dompurify";
import { refreshToken } from "./auth";

function parseSessionDate(session) {
  // Define possible months in French
  const monthNames = [
    "janvier",
    "février",
    "mars",
    "avril",
    "mai",
    "juin",
    "juillet",
    "août",
    "septembre",
    "octobre",
    "novembre",
    "décembre",
  ];

  // Check if the session contains a year range (e.g., "2017-2018 Rattrapage")
  const yearRangeMatch = session.match(/(\d{4})-(\d{4})/);
  if (yearRangeMatch) {
    const startYear = parseInt(yearRangeMatch[1], 10);
    const endYear = parseInt(yearRangeMatch[2], 10);
    // Use the end year to sort (for example, the 2017-2018 year is closer to 2018)
    return new Date(endYear, 0, 1); // Use January 1st of the end year
  }

  // Split session string for the typical "Résidanat 10 décembre 2022" or "Résidanat décembre 2015" format
  const parts = session.split(" ");
  const year = parseInt(parts[parts.length - 1], 10); // The last part is the year
  const month = monthNames.indexOf(parts[parts.length - 2]?.toLowerCase()); // The second last part is the month (if exists)

  // If there's a day (like "10 décembre 2022"), parse it; otherwise, default to 1
  const day = parts.length === 4 ? parseInt(parts[1], 10) : 1; // Default to day 1 if no day is provided

  // Return a Date object using year, month, and day (or default day)
  return new Date(year, month === -1 ? 0 : month, day); // Default month to 0 (January) if missing
}

export const getModules = createAsyncThunk(
  "api/medecine/modules",
  async (speciality, thunkAPI) => {
    try {
      const res = await fetch(
        `/api/medecine/modules/?speciality=${speciality}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      const data = await res.json();
      if (res.status === 200) {
        return data;
      } else {
        console.error("Error fetching modules:", data); // Debugging
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      console.error("Fetch error:", err); // Debugging
      return thunkAPI.rejectWithValue(
        err.response ? err.response.data : err.message
      );
    }
  }
);

export const getCourses = createAsyncThunk(
  "api/medecine/modules/courses",
  async (module_id, thunkAPI) => {
    try {
      const res = await fetch(
        `/api/medecine/modules/courses/?module_id=${module_id}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      if (!res.ok) {
        const errorData = await res.json();
        const errorMessage = errorData.error || "Failed to retrieve courses";
        console.error(`Error: ${res.status} - ${errorMessage}`);
        throw new Error(errorMessage);
      }

      const data = await res.json();
      return data;
    } catch (err) {
      console.error(`Error: ${err.message}`);
      return thunkAPI.rejectWithValue(
        err.message || "Something went wrong when trying to retrieve courses"
      );
    }
  }
);

export const getSources = createAsyncThunk(
  "api/medecine/modules/courses/sources",
  async (course_id, thunkAPI) => {
    try {
      const res = await fetch(
        `/api/medecine/modules/courses/sources/?course_id=${course_id}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      if (!res.ok) {
        const errorData = await res.json();
        const errorMessage = errorData.error || "Failed to retrieve courses";
        console.error(`Error: ${res.status} - ${errorMessage}`);
        throw new Error(errorMessage);
      }

      const data = await res.json();
      return data;
    } catch (err) {
      console.error(`Error: ${err.message}`);
      return thunkAPI.rejectWithValue(
        err.message || "Something went wrong when trying to retrieve courses"
      );
    }
  }
);

export const createSession = createAsyncThunk(
  "api/medecine/sessions/create",
  async (
    { sources, course_id, module_id, restart_session, years },
    thunkAPI
  ) => {
    try {
      const body = JSON.stringify({
        sources,
        course_id,
        module_id,
        restart_session,
        years,
      });

      const res = await fetch("/api/medecine/sessions/create", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body,
      });

      if (!res.ok) {
        const errorData = await res.json();
        const errorMessage =
          errorData.error || `Failed to create session. Status: ${res.status}`;
        console.error(`Error: ${res.status} - ${errorMessage}`);
        throw new Error(errorMessage);
      }

      const data = await res.json();
      return data;
    } catch (err) {
      console.error(`Error: ${err.message}`);
      return thunkAPI.rejectWithValue(
        err.message || "Something went wrong when trying to create session"
      );
    }
  }
);

export const getSessionQuestions = createAsyncThunk(
  "api/medecine/sessions/getSessionQuestions",
  async (
    { session_id, restart_session = false, restart_wrong = false },
    thunkAPI
  ) => {
    try {
      const queryParams = new URLSearchParams();
      if (restart_session) queryParams.append("restart_session", "true");
      if (restart_wrong) queryParams.append("restart_wrong", "true");

      const res = await fetch(
        `/api/medecine/sessions/${session_id}/?${queryParams.toString()}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      if (!res.ok) {
        const errorData = await res.json();
        const errorMessage =
          errorData.error || `Failed to fetch questions. Status: ${res.status}`;
        console.error(`Error: ${res.status} - ${errorMessage}`);
        throw new Error(errorMessage);
      }

      const data = await res.json();
      return data;
    } catch (err) {
      console.error(`Error: ${err.message}`);
      return thunkAPI.rejectWithValue(
        err.message || "Something went wrong when trying to fetch questions"
      );
    }
  }
);

export const getQuestions = createAsyncThunk(
  "api/medecine/modules/courses/sources/revision",
  async (
    {
      sources,
      course_id,
      module_id,
      restart_session,
      selected_part_cours,
      continue_session,
      page,
      years,
      session_id,
    },
    thunkAPI
  ) => {
    try {
      const body = JSON.stringify({
        sources,
        course_id,
        module_id,
        restart_session,
        selected_part_cours,
        continue_session,
        page,
        years,
        session_id,
      });

      const res = await fetch(
        "/api/medecine/modules/courses/sources/revision",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body,
        }
      );

      const data = await res.json();

      if (res.status === 401) {
        const { dispatch } = thunkAPI;
        dispatch(refreshToken());
      }

      if (res.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue({
          error: data.error || "Something went wrong...",
        });
      }
    } catch (err) {
      console.error(`Error: ${err.message}`);
      return thunkAPI.rejectWithValue({
        error: err.message || "Something went wrong...",
      });
    }
  }
);

export const getExams = createAsyncThunk(
  "api/medecine/exams",
  async (_, thunkAPI) => {
    try {
      const res = await fetch(`/api/medecine/exams/`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      const data = await res.json();
      if (res.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getWhiteExamAPI = createAsyncThunk(
  "api/medecine/white-exam",
  async ({ module_id }, thunkAPI) => {
    try {
      const res = await fetch(`/api/medecine/white-exam/${module_id}/`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      const data = await res.json();
      if (res.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const postAnswer = createAsyncThunk(
  "api/medecine/modules/courses/sources/revision/answer",
  async (
    { question_id, proposition_ids, evaluation, session_id },
    thunkAPI
  ) => {
    try {
      const body = JSON.stringify({
        question_id,
        proposition_ids,
        evaluation,
        session_id,
      });
      const res = await fetch(
        "/api/medecine/modules/courses/sources/revision/answer",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body,
        }
      );

      if (!res.ok) {
        const errorData = await res.json();
        const errorMessage =
          errorData.error ||
          `Failed to submit the answer. Status: ${res.status}`;
        console.error(`Error: ${res.status} - ${errorMessage}`);
        throw new Error(errorMessage);
      }

      const data = await res.json();
      return data;
    } catch (err) {
      console.error(`Error: ${err.message}`);
      return thunkAPI.rejectWithValue(
        err.message || "Something went wrong when trying to submit the answer"
      );
    }
  }
);

export const postComment = createAsyncThunk(
  "api/medecine/comments",
  async ({ question_id, content }, thunkAPI) => {
    try {
      const body = JSON.stringify({
        question_id,
        content,
      });
      const res = await fetch("/api/medecine/comments", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body,
      });

      if (!res.ok) {
        const errorData = await res.json();
        const errorMessage =
          errorData.error ||
          `Failed to submit the content. Status: ${res.status}`;
        console.error(`Error: ${res.status} - ${errorMessage}`);
        throw new Error(errorMessage);
      }

      const data = await res.json();
      return data;
    } catch (err) {
      console.error(`Error: ${err.message}`);
      return thunkAPI.rejectWithValue(
        err.message || "Something went wrong when trying to submit the content"
      );
    }
  }
);

export const updateComment = createAsyncThunk(
  "api/medecine/comments/update",
  async ({ commentId, editedContent }, thunkAPI) => {
    try {
      const body = JSON.stringify({ editedContent });
      const res = await fetch(`/api/medecine/comments/${commentId}/`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body,
      });

      if (!res.ok) {
        const errorData = await res.json();
        const errorMessage =
          errorData.error ||
          `Failed to update the comment. Status: ${res.status}`;
        console.error(`Error: ${res.status} - ${errorMessage}`);
        throw new Error(errorMessage);
      }

      const data = await res.json();
      return data;
    } catch (err) {
      console.error(`Error: ${err.message}`);
      return thunkAPI.rejectWithValue(
        err.message || "Something went wrong when trying to update the comment"
      );
    }
  }
);

export const deleteComment = createAsyncThunk(
  "api/medecine/comments/delete",
  async ({ commentId }, thunkAPI) => {
    try {
      const res = await fetch(`/api/medecine/comments/${commentId}/`, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
        },
      });

      if (!res.ok) {
        const errorData = await res.json();
        const errorMessage =
          errorData.error ||
          `Failed to delete the comment. Status: ${res.status}`;
        console.error(`Error: ${res.status} - ${errorMessage}`);
        throw new Error(errorMessage);
      }

      return { commentId };
    } catch (err) {
      console.error(`Error: ${err.message}`);
      return thunkAPI.rejectWithValue(
        err.message || "Something went wrong when trying to delete the comment"
      );
    }
  }
);

export const postTodo = createAsyncThunk(
  "api/medecine/todo",
  async ({ task_description, due_date, priority }, thunkAPI) => {
    try {
      const body = JSON.stringify({
        task_description,
        due_date,
        priority,
      });
      const res = await fetch("/api/medecine/todo/post", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body,
      });

      if (!res.ok) {
        const errorData = await res.json();
        const errorMessage =
          errorData.error ||
          `Failed to submit the content. Status: ${res.status}`;
        console.error(`Error: ${res.status} - ${errorMessage}`);
        throw new Error(errorMessage);
      }

      const data = await res.json();
      return data;
    } catch (err) {
      console.error(`Error: ${err.message}`);
      return thunkAPI.rejectWithValue(
        err.message || "Something went wrong when trying to submit the todo"
      );
    }
  }
);

export const getSessions = createAsyncThunk(
  "api/medecine/sessions",
  async (_, thunkAPI) => {
    try {
      const res = await fetch("/api/medecine/sessions/", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      if (!res.ok) {
        const errorData = await res.json();
        const errorMessage = errorData.error || "Failed to retrieve sessions";
        console.error(`Error: ${res.status} - ${errorMessage}`);
        throw new Error(errorMessage);
      }

      const data = await res.json();
      return data;
    } catch (err) {
      console.error(`Error: ${err.message}`);
      return thunkAPI.rejectWithValue(
        err.message || "Something went wrong when trying to retrieve sessions"
      );
    }
  }
);

export const getExamQuestions = createAsyncThunk(
  "api/medecine/exams/questions",
  async ({ exam_id }, thunkAPI) => {
    try {
      const body = JSON.stringify({
        exam_id,
      });

      const res = await fetch("/api/medecine/exams/questions", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body,
      });

      if (!res.ok) {
        const errorData = await res.json();
        const errorMessage =
          errorData.error ||
          `Failed to retrieve questions. Status: ${res.status}`;
        console.error(`Error: ${res.status} - ${errorMessage}`);
        throw new Error(errorMessage);
      }

      const data = await res.json();
      return data;
    } catch (err) {
      console.error(`Error: ${err.message}`);
      return thunkAPI.rejectWithValue(
        err.message || "Something went wrong when trying to retrieve questions"
      );
    }
  }
);

export const postFlashcard = createAsyncThunk(
  "api/medecine/aidememoire/post",
  async ({ flashcard_id, is_correct }, thunkAPI) => {
    try {
      const body = JSON.stringify({
        flashcard_id,
        is_correct,
      });

      const res = await fetch("/api/medecine/aidememoire/post", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body,
      });

      if (!res.ok) {
        const errorData = await res.json();
        const errorMessage =
          errorData.error || `Failed to post flashcard. Status: ${res.status}`;
        console.error(`Error: ${res.status} - ${errorMessage}`);
        throw new Error(errorMessage);
      }

      const data = await res.json();
      return data;
    } catch (err) {
      console.error(`Error: ${err.message}`);
      return thunkAPI.rejectWithValue(
        err.message || "Something went wrong when trying to post flashcard"
      );
    }
  }
);

export const getFlashcards = createAsyncThunk(
  "api/medecine/aidememoire",
  async (course_id, thunkAPI) => {
    try {
      const res = await fetch(
        `/api/medecine/aidememoire/?course_id=${course_id}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      const data = await res.json();

      if (res.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const postNote = createAsyncThunk(
  "api/medecine/notes/post",
  async ({ course_id, content }, thunkAPI) => {
    try {
      const sanitizedContent = DOMPurify.sanitize(content);
      const body = JSON.stringify({
        course_id,
        content: sanitizedContent,
      });

      const res = await fetch("/api/medecine/notes/post", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body,
      });

      if (!res.ok) {
        const errorData = await res.json();
        const errorMessage =
          errorData.error || `Failed to post note. Status: ${res.status}`;
        console.error(`Error: ${res.status} - ${errorMessage}`);
        throw new Error(errorMessage);
      }

      const data = await res.json();
      return data;
    } catch (err) {
      console.error(`Error: ${err.message}`);
      return thunkAPI.rejectWithValue(
        err.message || "Something went wrong when trying to post note"
      );
    }
  }
);

export const postReport = createAsyncThunk(
  "api/medecine/report",
  async ({ question_id, report_text }, thunkAPI) => {
    try {
      const sanitizedComment = DOMPurify.sanitize(report_text);
      const body = JSON.stringify({
        question_id,
        report_text: sanitizedComment,
      });

      const res = await fetch("/api/medecine/report", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body,
      });

      if (!res.ok) {
        const errorData = await res.json();
        const errorMessage =
          errorData.error || `Failed to post note. Status: ${res.status}`;
        console.error(`Error: ${res.status} - ${errorMessage}`);
        throw new Error(errorMessage);
      }

      const data = await res.json();
      return data;
    } catch (err) {
      console.error(`Error: ${err.message}`);
      return thunkAPI.rejectWithValue(
        err.message || "Something went wrong when trying to post note"
      );
    }
  }
);

export const getModulesPerNotes = createAsyncThunk(
  "api/medecine/notes/modules",
  async (speciality, thunkAPI) => {
    try {
      const res = await fetch(
        `/api/medecine/notes/modules/?speciality=${speciality}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      const data = await res.json();
      if (res.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getNote = createAsyncThunk(
  "api/medecine/notes",
  async (course_id, thunkAPI) => {
    try {
      const res = await fetch(`/api/medecine/notes/?course_id=${course_id}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      const data = await res.json();
      if (res.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const createPlaylist = createAsyncThunk(
  "api/medecine/create_playlist",
  async ({ question_id }, thunkAPI) => {
    try {
      const body = JSON.stringify({
        question_id,
      });

      const res = await fetch("/api/medecine/create_playlist", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body,
      });

      if (!res.ok) {
        const errorData = await res.json();
        const errorMessage =
          errorData.error || `Failed to create playlist. Status: ${res.status}`;
        console.error(`Error: ${res.status} - ${errorMessage}`);
        throw new Error(errorMessage);
      }

      const data = await res.json();
      return data;
    } catch (err) {
      console.error(`Error: ${err.message}`);
      return thunkAPI.rejectWithValue(
        err.message || "Something went wrong when trying to create playlist"
      );
    }
  }
);

export const getPlaylistModules = createAsyncThunk(
  "api/medecine/playlist_modules",
  async (_, thunkAPI) => {
    try {
      const res = await fetch(`/api/medecine/playlist_modules/`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      const data = await res.json();
      if (res.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getPlaylistQuestions = createAsyncThunk(
  "api/medecine/playlist_questions/",
  async ({ course_id, page }, thunkAPI) => {
    try {
      const res = await fetch(
        `/api/medecine/playlist_questions/${course_id}/?page=${page}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      const data = await res.json();

      if (res.status === 401) {
        const { dispatch } = thunkAPI;
        dispatch(refreshToken());
      }

      if (res.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue({
          error: data.error || "Something went wrong...",
        });
      }
    } catch (err) {
      console.error(`Error: ${err.message}`);
      return thunkAPI.rejectWithValue({
        error: err.message || "Something went wrong...",
      });
    }
  }
);

export const getStatistics = createAsyncThunk(
  "api/medecine/statistics",
  async (_, thunkAPI) => {
    try {
      const res = await fetch("/api/medecine/statistics/", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      if (!res.ok) {
        const errorData = await res.json();
        const errorMessage = errorData.error || "Failed to retrieve statistics";
        console.error(`Error: ${res.status} - ${errorMessage}`);
        throw new Error(errorMessage);
      }

      const data = await res.json();
      return data;
    } catch (err) {
      console.error(`Error: ${err.message}`);
      return thunkAPI.rejectWithValue(
        err.message || "Something went wrong when trying to retrieve statistics"
      );
    }
  }
);

export const getTodos = createAsyncThunk(
  "api/medecine/todos",
  async (_, thunkAPI) => {
    try {
      const res = await fetch("/api/medecine/todos/", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      if (!res.ok) {
        const errorData = await res.json();
        const errorMessage = errorData.error || "Failed to retrieve todos";
        console.error(`Error: ${res.status} - ${errorMessage}`);
        throw new Error(errorMessage);
      }

      const data = await res.json();
      return data;
    } catch (err) {
      console.error(`Error: ${err.message}`);
      return thunkAPI.rejectWithValue(
        err.message || "Something went wrong when trying to retrieve todos"
      );
    }
  }
);

const initialState = {
  loading: false,
  loadingQuestions: false,
  modules: null,
  courses: null,
  sources: null,
  todos: null,
  questions: [],
  questions_length: null,
  examQuestions: null,
  note: null,
  exams: null,
  sessions: null,
  session_id: null,
  flashcards: null,
  modulesPerNotes: null,
  playlist: null,
  playlistModules: [],
  statistics: null,
  selectedModuleId: null,
  selectedCourseId: null,
  selectedSourcesIds: null,
  selectedYears: null,
  restart: false,
  error: null,
};

const medecineSlice = createSlice({
  name: "medecine",
  initialState,
  reducers: {
    resetNote: (state) => {
      state.note = null;
    },
    resetRestart: (state) => {
      state.restart = false;
    },
    resetQuestions: (state) => {
      state.questions = [];
      state.modulesPerNotes = null;
    },
    resetModules: (state) => {
      state.modules = null;
    },
    resetCourses: (state) => {
      state.courses = null;
    },
    resetSources: (state) => {
      state.sources = null;
    },
    filterQuestionsByPartCours: (state, action) => {
      const { selectedPartCours } = action.payload;
      state.questions = state.questions.filter((question) =>
        selectedPartCours.includes(question.part_cours)
      );
      state.questions_length = state.questions.length;
    },
    resetError: (state) => {
      state.error = null;
    },
    resetExamQuestions: (state) => {
      state.examQuestions = null;
    },
    resetPlaylist: (state) => {
      state.playlist = null;
    },
    setQuestion: (state, action) => {
      state.questions = [...state.questions, ...action.payload];
    },
    setQuestionLength: (state, action) => {
      state.questions_length = action.payload;
    },
    setCourses: (state, action) => {
      state.courses = action.payload;
    },
    setSelectedModuleId: (state, action) => {
      state.selectedModuleId = action.payload;
    },
    setSelectedCourseId: (state, action) => {
      state.selectedCourseId = action.payload;
    },
    setSelectedSourcesIds: (state, action) => {
      state.selectedSourcesIds = action.payload;
    },
    setSelectedYearsIds: (state, action) => {
      state.selectedYears = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getModules.pending, (state) => {
        state.loading = true;
      })
      .addCase(getModules.fulfilled, (state, action) => {
        state.loading = false;
        state.modules = action.payload;
        state.error = null;
      })
      .addCase(getModules.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getCourses.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCourses.fulfilled, (state, action) => {
        state.loading = false;
        state.courses = action.payload.sort((a, b) =>
          a.title.localeCompare(b.title)
        );
        state.error = null;
      })
      .addCase(getCourses.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getSources.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSources.fulfilled, (state, action) => {
        state.loading = false;
        state.sources = action.payload;
        state.error = null;
      })
      .addCase(getSources.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createSession.pending, (state) => {
        state.loading = true;
      })
      .addCase(createSession.fulfilled, (state, action) => {
        state.loading = false;
        state.questions = action.payload["questions"];
        state.session_id = action.payload["session_id"];
        state.questions_length = action.payload["questions_length"];
        state.error = null;
      })
      .addCase(createSession.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getSessionQuestions.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSessionQuestions.fulfilled, (state, action) => {
        state.loading = false;
        // Check if restart flag is set in the action payload
        const { restart_session, restart_wrong } = action.meta.arg;

        if (restart_session || restart_wrong) {
          // Replace the existing questions with the new ones if restart is true
          state.questions = action.payload["questions"];
          state.restart = true;
        } else {
          // Append new questions if restart is not true
          state.questions = [
            ...state.questions,
            ...action.payload["questions"],
          ];
        }
        state.session_id = action.payload["session_id"];
        state.questions_length = action.payload["questions_length"];
        state.error = null;
      })
      .addCase(getSessionQuestions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getPlaylistQuestions.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPlaylistQuestions.fulfilled, (state, action) => {
        state.loading = false;
        state.session_id = null;
        state.questions = [...state.questions, ...action.payload["questions"]];
        state.questions_length = action.payload["questions_length"];
        state.error = null;
      })
      .addCase(getPlaylistQuestions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getQuestions.pending, (state) => {
        state.loadingQuestions = true;
      })
      .addCase(getQuestions.fulfilled, (state, action) => {
        state.loadingQuestions = false;
        state.questions = [...state.questions, ...action.payload];
        state.questions_length = action.payload[0].count;
        state.error = null;
      })
      .addCase(getQuestions.rejected, (state, action) => {
        state.loadingQuestions = false;
        state.error = action.payload;
      })
      .addCase(postAnswer.pending, (state) => {
        state.loading = true;
      })
      .addCase(postAnswer.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(postAnswer.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getSessions.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSessions.fulfilled, (state, action) => {
        state.loading = false;
        state.sessions = action.payload;
        state.error = null;
      })
      .addCase(getSessions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getExams.pending, (state) => {
        state.loading = true;
      })
      .addCase(getExams.fulfilled, (state, action) => {
        state.loading = false;
        state.exams = state.exams = action.payload.sort((a, b) => {
          const dateA = parseSessionDate(a.session);
          const dateB = parseSessionDate(b.session);

          return dateB - dateA;
        });
        state.error = null;
      })
      .addCase(getExams.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getWhiteExamAPI.pending, (state) => {
        state.loading = true;
      })
      .addCase(getWhiteExamAPI.fulfilled, (state, action) => {
        state.loading = false;
        state.examQuestions = action.payload;
        state.error = null;
      })
      .addCase(getWhiteExamAPI.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getFlashcards.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFlashcards.fulfilled, (state, action) => {
        state.loading = false;
        state.flashcards = action.payload;
        state.error = null;
      })
      .addCase(getFlashcards.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(postFlashcard.pending, (state) => {
        state.loading = true;
      })
      .addCase(postFlashcard.fulfilled, (state, action) => {
        state.loading = false;
        state.flashcards = action.payload;
        state.error = null;
      })
      .addCase(postFlashcard.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(postNote.pending, (state) => {
        state.loading = true;
      })
      .addCase(postNote.fulfilled, (state, action) => {
        const note = action.payload;

        state.loading = false;

        // Find the question in state.questions and update its note property
        const updatedQuestions = state.questions.map((question) => {
          if (question.id === note.question) {
            // Update the note property of the question with the note data
            return { ...question, note };
          }
          return question;
        });

        state.questions = updatedQuestions;
        state.note = action.payload;
        state.error = null;
      })
      .addCase(postNote.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getNote.pending, (state) => {
        state.loading = true;
      })
      .addCase(getNote.fulfilled, (state, action) => {
        state.loading = false;
        state.note = action.payload;
        state.error = null;
      })
      .addCase(getNote.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.note = null;
      })
      .addCase(getModulesPerNotes.pending, (state) => {
        state.loading = true;
      })
      .addCase(getModulesPerNotes.fulfilled, (state, action) => {
        state.loading = false;
        state.modulesPerNotes = action.payload;
        state.error = null;
      })
      .addCase(getModulesPerNotes.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(createPlaylist.pending, (state) => {
        state.loading = true;
      })
      .addCase(createPlaylist.fulfilled, (state, action) => {
        const { question_id, is_playlist } = action.payload;
        state.loading = false;
        state.playlist = is_playlist;
        // Find the question in state.questions and update its is_playlist property
        const updatedQuestions = state.questions.map((question) => {
          if (question.id === question_id) {
            return { ...question, is_playlist };
          }
          return question;
        });

        state.questions = updatedQuestions;
        state.error = null;
      })
      .addCase(createPlaylist.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getPlaylistModules.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPlaylistModules.fulfilled, (state, action) => {
        state.loading = false;
        state.playlistModules = action.payload;
        state.error = null;
      })
      .addCase(getPlaylistModules.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getStatistics.pending, (state) => {
        state.loading = true;
      })
      .addCase(getStatistics.fulfilled, (state, action) => {
        state.loading = false;
        state.statistics = action.payload;
        state.error = null;
      })
      .addCase(getStatistics.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(postReport.pending, (state) => {
        state.loading = true;
      })
      .addCase(postReport.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(postReport.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getExamQuestions.pending, (state) => {
        state.loading = true;
      })
      .addCase(getExamQuestions.fulfilled, (state, action) => {
        state.loading = false;
        state.examQuestions = action.payload;
        state.error = null;
      })
      .addCase(getExamQuestions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getTodos.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTodos.fulfilled, (state, action) => {
        state.loading = false;
        state.todos = action.payload;
        state.error = null;
      })
      .addCase(getTodos.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(postComment.pending, (state) => {
        state.loading = true;
      })
      .addCase(postComment.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;

        // Find the question that matches the question_id of the newly created comment
        const { id, user, content, created_at, question_id } = action.payload;
        const updatedQuestions = state.questions.map((question) => {
          if (question.id === question_id) {
            // Append the new comment to the question's comments array
            return {
              ...question,
              comments: [
                ...question.comments,
                { id, user, content, created_at, question_id },
              ],
            };
          }
          return question;
        });

        state.questions = updatedQuestions;
      })
      .addCase(postComment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateComment.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateComment.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;

        const { id, content, created_at, question_id } = action.payload;
        const updatedQuestions = state.questions.map((question) => {
          if (question.id === question_id) {
            const updatedComments = question.comments.map((comment) => {
              if (comment.id === id) {
                return { ...comment, content, created_at };
              }
              return comment;
            });

            return {
              ...question,
              comments: updatedComments,
            };
          }
          return question;
        });

        state.questions = updatedQuestions;
      })
      .addCase(updateComment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteComment.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteComment.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;

        const { commentId } = action.payload;
        const updatedQuestions = state.questions.map((question) => ({
          ...question,
          comments: question.comments.filter(
            (comment) => comment.id !== commentId
          ),
        }));

        state.questions = updatedQuestions;
      })
      .addCase(deleteComment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});
export const {
  resetRestart,
  setQuestionLength,
  resetQuestions,
  setQuestion,
  resetNote,
  resetPlaylist,
  resetExamQuestions,
  resetError,
  filterQuestionsByPartCours,
  setSelectedCourseId,
  setSelectedModuleId,
  setSelectedSourcesIds,
  setSelectedYearsIds,
  resetModules,
  resetCourses,
  resetSources,
  setCourses,
} = medecineSlice.actions;

export default medecineSlice.reducer;
