import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { default as SubsInfo } from "./SubsInfo";
import {
  getPlans,
  getActiveSubscription,
  addSubscription,
} from "features/subscription";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";

const UpgradePlanModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activationCode, setActivationCode] = useState("");
  const [subActivated, setSubActivated] = useState(false);
  useEffect(() => {
    dispatch(getPlans());
    dispatch(getActiveSubscription());
  }, [dispatch]);
  
  const handleActivation = async () => {
    try {
      // Track the activation event
      ReactGA.event({
        category: "Subscription",
        action: "Activate",
        label: "Subscription Activation Attempt",
        value: null, // No sensitive data sent
      });

      await dispatch(addSubscription({ activation_code: activationCode }));
      setSubActivated(true);
    } catch (error) {
      console.error("Error adding subscription:", error);
      // Handle error if necessary
    }
  };

  if (subActivated) {
    setSubActivated(false);
    localStorage.setItem("subActivated", "true");
    setTimeout(() => {
      navigate(0);
    }, 100);
  }
  return (
    <div
      className="modal fade"
      id="kt_modal_upgrade_plan"
      tabIndex="-1"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-xl">
        <div className="modal-content rounded">
          <div className="modal-header justify-content-end border-0 pb-0">
            <div
              className="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-dismiss="modal"
            >
              <i className="ki-duotone ki-cross fs-1">
                <span className="path1"></span>
                <span className="path2"></span>
              </i>
            </div>
          </div>
          <div className="modal-body pt-0 pb-15 px-5 px-xl-20">
            <div className="row">
              <div className="col-12 col-md-6 mb-13 text-start">
                <h1 className="mb-3">Mettre à niveau votre plan</h1>
              </div>
              <div className="col-12 col-md-6 mb-13 text-start">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Avez-vous un code d'activation ?"
                    value={activationCode}
                    onChange={(e) => setActivationCode(e.target.value)}
                  />
                  <button
                    class="btn btn-outline btn-outline-dashed btn-outline-danger btn-active-light-danger flex-shrink-0"
                    data-clipboard-target="#kt_clipboard_1"
                    onClick={handleActivation}
                  >
                    Activer
                  </button>
                </div>
              </div>
            </div>

            <SubsInfo />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpgradePlanModal;